body {
  background: rgba(0, 0, 0, 0.85);
  margin: 0;
}

.slider {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 90vw;
  height: 60vh;
  margin: 0 auto;
  padding: 0;
  @media screen and (max-width: 600px) {
    height: 30vh;
    width: 90vw;
  }
}

.slider li {
  position: relative;
  background: none no-repeat center/cover;
  min-height: 100%;
  overflow: hidden;
  flex-grow: 1;
  transition: 0.75s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 5vw 0 5vw 0;
}

.slider li::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #77777774;
  transition: background 0.75s cubic-bezier(0.4, 0, 0.2, 1);
}

.slider li:hover {
  flex-grow: 5;
  filter: grayscale(0%);
  border-radius: 5vw 0 5vw 0;
}

.slider li:hover::before {
  background: rgba(0, 0, 0, 0);
}

.slider h1 {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(90deg);
  transition: transform 0.5s cubic-bezier(0.35, 0, 0, 1);
  width: 25vw;
  color: #fff;
  font-size: 2vw;
  background: rgba(0, 0, 0, 0.8); /* Black background */
  line-height: 1.5;
  height: 15vh;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  transform-origin: left top;
  @media screen and (max-width: 600px) {
    top: 30vh;
    width: 50vw;
  }
}
.slider h3 {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(90deg);
  transition: transform 0.5s cubic-bezier(0.35, 0, 0, 1);
  width: 25vw;
  color: #fff;
  font-size: 2vw;
  background: rgba(0, 0, 0, 0.8); /* Black background */
  line-height: 1.5;
  height: 15vh;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  transform-origin: left top;
  @media screen and (max-width: 600px) {
    top: 30vh;
    width: 50vw;
  }
}

.slider li:hover h1 {
  transform: rotateZ(0) translate(-50%, -50%);
}

.slider .one {
  background-image: url(./InternImages/ado.png);
  filter: grayscale(30%);
}

.slider .two {
  background-image: url(./InternImages/ben.png);
  filter: grayscale(30%);
}

.slider .three {
  background-image: url(./InternImages/migs.png);
  filter: grayscale(30%);
}

.slider .four {
  background-image: url(./InternImages/randy.png);
  filter: grayscale(30%);
}

.slider .five {
  background-image: url(./InternImages/andrea.png);
  filter: grayscale(30%);
}

.slider .six {
  background-image: url(./InternImages/angie.png);
  filter: grayscale(30%);
}

.slider .seven {
  background-image: url(./InternImages/frenalyn.png);
  filter: grayscale(30%);
}

.slider .eight {
  background-image: url(./InternImages/joan.png);
  filter: grayscale(30%);
}

.slider .nine {
  background-image: url(./InternImages/nove.png);
  filter: grayscale(30%);
}

.slider .ten {
  background-image: url(./InternImages/pat.png);
  filter: grayscale(30%);
}

.slider .eleven {
  background-image: url(./InternImages/frenan.png);
  filter: grayscale(30%);
}

.slider .twelve {
  background-image: url(./InternImages/ryan.png);
  filter: grayscale(30%);
}

.slider .thirteen {
  background-image: url(./InternImages/joemar.png);
  filter: grayscale(30%);
}

.slider .fourteen {
  background-image: url(./InternImages/Isah.png);
  filter: grayscale(30%);
}
